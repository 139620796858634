:root {
    --white2: #F7F6F6; 
    --secondary: #D9D9D9;
    --bento-bkg: #FFFFFF;
    --global-card-corner-radius: 28px;
    --nav-bar-height: 88px;
    --color-text-gradient: radial-gradient(129.03% 100% at 120.97% 81.45%,#dfdcff 27.08%,#a69eff 100%);
    --section-margin: 0 2%;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    width: 100%;
    background-color: #F0F0F0;
}

.solutionSection {
    margin: 0;
    width: 100%;
    height: 100vh;
}

.bentoIntro {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 0;
    height: 100vh;
    width: 100%;
    /* top: calc(var(--nav-bar-height) + 1rem); */
    /* height: calc(100vh - var(--nav-bar-height) - 2rem); */
    /* width: 96%; */

    margin: 0 auto;
    /* border-radius: var(--global-card-corner-radius); */
    border-radius: 0;
    background-color: var(--bento-bkg);
}

.introVid {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* border-radius: var(--global-card-corner-radius); */
    border-radius: 0;
}

.introHeading {
    color: #FFFFFF;
    margin: 0;
    /* font-size: 10rem; */
    font-size: clamp(2rem, 12vw, 10rem);
    text-align: center;
    z-index: 10;
}

.contactHeading {
    color: #000000;
    margin: 0;
    font-size: 10rem;
    text-align: center;
    z-index: 10;
}


.contactSection {
    margin: 0;
    padding-bottom: 20px;
}

.bentoContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    height: 100vh;
    margin: var(--section-margin);

    border-radius: var(--global-card-corner-radius);
    background-color: var(--bento-bkg);
}



@media(max-width: 1366px) {
    .introHeading {
        /* font-size: 8rem; */
    }
}
@media(max-width: 1024px) {

}
@media(max-width: 768px) {
    :root {
        --section-margin: 0 3%;
    }
}
@media(max-width: 600px) {
    :root {
        --global-card-corner-radius: 20px;
        --section-margin: 0 4%;
    }
}
@media(max-width: 425px) {
    :root {
        --global-card-corner-radius: 16px;
        --section-margin: 0 5%;
    }
}


