.container{
    background-color: #fff;
    min-width: 100vw;
    min-height: 100vh;

    overflow: hidden;
}

.scrollContainer{
    position: absolute;

    bottom: 20px;
    right: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    writing-mode: vertical-rl; 
    color: #000;
}

.scrollContainer svg{
    max-width: 50px;
    max-height: 50px;
    
    transform: rotate(270deg) scaleX(-1);
}
.scrollContainer img{
    position: static;
    max-width: 50px;
    max-height: 50px;
    
    transform: rotate(270deg) scaleX(-1);
}

.scrollContainer p{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;

    font-size: 18px;
    margin: 0;
}

@media screen and (max-width: 750px) {

    .scrollContainer svg{
        max-width: 40px;
        max-height: 40px;
    }
    .scrollContainer img{
        max-width: 40px;
        max-height: 40px;
    }
    
    .scrollContainer p{
        font-size: 14px;
    }
}