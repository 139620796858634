body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
html, body{
  margin: 0;
  min-height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Transition overlay */
.transition-overlay {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000; /* Background color of the overlay */

  z-index: 9999; /* Ensure the overlay is above everything */
  pointer-events: none; /* Prevent interaction with the overlay */
  transition: top 0.5s ease-in-out; /* Ensure smooth scaling */

  will-change: transform;
}

/* FeaturedWork.css */
.featured-work {
  display: flex;
  flex-wrap: wrap;

  flex-direction: column;
  width: 100%;
}

.featured-work h1{
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;

  font-size: 24px;
  
  margin: 0;
  margin-left: 40px;
}

.work-item{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  gap: 10px;

  padding: 10px 0px;
  position: relative; /* Ensure positioning context for absolute elements */

  cursor: pointer;

  text-decoration: none;
  color: #000;
}

.work-item{
  border-top: 1px solid #000;
}

.featured-work:last-child{
  border-bottom: 1px solid #000;
}

.work-item h3{
  /* font-family: "Bodoni Moda SC", serif; */
    font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;

  font-size: 28px;
  letter-spacing: 15px;

  margin: 0;

  transition: letter-spacing 0.3s linear, word-spacing 0.3s linear;


  margin: 0px 40px;
}

.work-item:hover h3{
  letter-spacing: 30px;
  word-spacing: 60px;
}

.work-item img {
  position: absolute;
  width: 300px;
  opacity: 0;
  pointer-events: none; /* Ensure the image doesn't interfere with mouse events */
  transition: opacity 0.3s ease;

  z-index: 9;
}

.work-item:hover img{
  opacity: 1;
}

.contact-studio{
  position: fixed;
  top: 100vh;
  left: 0;

  z-index: 9;
}

.aboutUs{
  width: 95vw;
  position: relative;

  transform: translate(0px, 0px) !important;
}

/* For project GSAP feature */
.projectInfo{
  min-width: 100vw;
  min-height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectInfo h1{
  margin: 0;
  font-size: 250px;

  font-family: "Bodoni Moda SC", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.projectInfo h2{
  margin: 0;
  font-size: 50px;


  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.projectDetailsContainer{
  width: 100vw;
  min-height: 100vh;

  position: relative;

  background-color: #000;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9;
}

.projectDetailsContainerFlex{
  width: 100%;
  height: 65%;
  
  padding: 0px 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

}

.projectDetailsElement{
  max-width: 750px;

  /* border: 1px solid #fff; */
  padding: 20px 50px;
}

.projectDetailsElement h3{
  margin: 0;
  font-size: 36px;
  padding-bottom: 75px;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.projectDetailsElement p{
  margin: 0;
  font-size: 18px;


  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.projectDetailsElement h4{
  margin: 0;
  font-size: 18px;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.projectDetailsElement ol{
  padding: 0;
  margin: 0;
  font-size: 18px;

  list-style-type: none;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.projectDetailsElement a{
  color: #fff;
  text-decoration: none;
  margin: 0;
  font-size: 18px;

  border: 1px solid #fff;

  padding: 5px 10px;
  border-radius: 20px;

  list-style-type: none;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  transition: 0.5s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectDetailsElement a:hover {
  padding: 7.5px 12.5px;
}

.projectDetailsElement a svg {
  margin-left: 10px;
  fill: #000; /* Initial fill color */
  stroke: #fff;
  stroke-width: 5px;
}

.projectDetailsElement a:hover svg {
  fill: #fff; /* Change circle color on hover */
  stroke: #fff;
}

.projectImages{
  min-width: 100vw;
  min-height: 100vh;

  position: relative;

  background-color: #fff;

  z-index: 9;
}

/* For horizontal scroll */
.horizontal-scroll-wrapper {
  width: 100vw; /* Full viewport width */
  height: 100vh;
  overflow: hidden; /* Hide overflow to prevent scrolling */
  position: relative;
}

.horizontal-scroll-container {
  display: flex;
  flex-direction: row;
  gap: 150px;
  width: fit-content;
  height: 100vh;

  padding: 0px 100px;

  position: absolute;

  align-items: center;
  
}

.scroll-item img {
  height: 80vh;
  object-fit: cover;

  border-radius: 20px;
}

/* For the mobile Bento */
.dynamicBentoTitle{
  padding: 40px 0px;
  font-size: 28px;
  text-align: center;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;

  margin: 0;
}

.dynamicBentoGrid {
  display: grid;

  padding: 0px 20px;
  padding-bottom: 40px;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.dynamicBentoGridItem {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: 100%;
}

.dynamicBentoGridItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

/* Wide images span the full width */
.dynamicBentoGridItem.wideImage {
  grid-column: span 2; /* Adjust based on the number of columns in your grid */
  width: 100%;
}

/* Regular images */
.dynamicBentoGridItem.regularImage {
  grid-column: span 1;
}

/* Hover effect */
.dynamicBentoGridItem:hover img {
  transform: scale(1.05);
}

/* For Next Project Stuff */
.nextProjectContainer{
    width: 100vw;
    height: 100vh;
  
    position: relative;
  
    background-color: #000;
    color: #fff;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: none;
  
    z-index: 9;
}

.nextProjectImageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: 90%;
  width: 90%;


  pointer-events: all;
}

.nextProjectImageContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

/* Hover effect */
.nextProjectImageContainer:hover img {
  transform: scale(1.025);
}

.nextPorjectInfoContainer{
  position: absolute;
  right: 20px;
  bottom: 20px;

  color: #fff;
  mix-blend-mode: difference;

  display: flex;
  flex-direction: column;
}

.nextPorjectInfoContainer h2{
  margin: 0;
  font-size: 24px;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.nextPorjectInfoContainer p{
  margin: 0;
  font-size: 16px;


  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.nextPorjectTitle{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;

  color: #fff;
  mix-blend-mode: difference;

  width: 100%;

  text-align: center;
  font-size: 100px;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

@media screen and (max-width: 1850px) {
  .work-item h3{
    font-size: 24px;
    margin: 0px 20px;
  }

  .work-item:hover h3{
    letter-spacing: 20px;
    word-spacing: 30px;
  }

  .featured-work h1{
    margin-left: 20px;
  }
}

@media screen and (max-width: 1500px) {
  /* For projects features */
  .projectInfo h1{
    font-size: 150px;
  }
}

@media screen and (max-width: 1450px) {
  .work-item h3{
    font-size: 22px;
    margin: 0px 10px;
  }

  .featured-work h1{
    margin-left: 10px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .work-item{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }

  /* For projects features */
  .projectInfo h1{
    font-size: 120px;
  }
  
  .projectInfo h2{
    font-size: 40px;
  }

  .projectDetailsContainerFlex{
    width: 100%;
    height: 100%;

    justify-content: center;
    gap: 20px;
    align-items: center;
  
  }

  .scroll-item img {
    height: 60vh;
  }
}

@media screen and (max-width: 800px) {
  /* For projects features */
  .projectInfo h1{
    font-size: 100px;
  }
  
  .projectInfo h2{
    font-size: 28px;
  }

  .projectDetailsContainerFlex{
    padding: 50px 20px;
  
  }

  .scroll-item img {
    height: 60vh;
  }
}

@media screen and (max-width: 700px) {
  /* For projects features */
  .projectInfo h1{
    font-size: 82px;
  }

  .nextPorjectTitle{
    font-size: 82px;
  }
}

@media screen and (max-width: 550px) {
  .work-item h3{
    font-size: 20px;
    margin: 0px 10px;
  }

  .featured-work h1{
    font-size: 14px;
  }

  /* For projects features */
  .projectDetailsContainerFlex{
    justify-content: flex-start;
    align-items: flex-start;
  }

  .projectInfo h1{
    font-size: 64px;
  }
  
  .projectInfo h2{
    font-size: 20px;
  }

  .projectDetailsContainerFlex{
    width: 100%;
    height: 65%;
    
    padding: 50px 0px;  
  }
  
  .projectDetailsElement{
    padding: 25px 50px; 
  }
  
  .projectDetailsElement h3{
    font-size: 36px;
    padding-bottom: 75px;
  }
  
  .projectDetailsElement p{
    font-size: 18px;
  }
  
  .projectDetailsElement h4{
    font-size: 18px;
  }
  
  .projectDetailsElement ol{
    font-size: 18px;
  }

  .nextPorjectTitle{
    font-size: 48px;
  }
}

@media screen and (max-width: 400px) {
  .projectInfo h1{
    font-size: 48px;
  }

  .nextPorjectTitle{
    font-size: 36px;
  }
}