.header {
    width: 100vw;
    padding: 1rem;
  
    top: 0;

    /* border-bottom: solid 2px #000; */
  
    mix-blend-mode: difference;
    filter: invert(1);
    
    position: fixed;
  
    z-index: 100;
  }
  
  .navbar{
    display: flex;
    justify-content: space-between;
  }
  
  .logo{
    height: 50px;
  }
  
  .list {
    display: flex;
    align-items: center;
    gap: 1rem;
    list-style: none;
  
    padding: 0px 20px;
    margin: 0;
  }

  .list li{
    position: relative;
  }

  .list li svg{
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  .list a {
    text-decoration: none;

    color: #000;
    
    font-size: 18px;
  
    margin: 0 10px;
    padding: 0px 10px;

    transition: 0.2s linear;
  }

.languageToggle {
  display: flex;
  align-items: center;
  height: 2rem;

  margin: 0 0.75rem;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  gap: 0;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 0.5rem;
  z-index: 100;
}
.langImg {
  width: 22px;
}
select {
  border: 0px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  cursor: pointer;
}
select:focus {
  outline: none;
  border-color: #007bff;
}

  /*
    For the navbar
  */
  .scribble{
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    opacity: 1;
    transition: stroke-dashoffset 0.5s ease, opacity 0.3s ease-in-out;
  
    fill: none; 
    stroke: #000; 
    stroke-width: 1;
  }

  .list a:hover .scribble {
    stroke-dashoffset: 0;
    opacity: 1;

    transition: stroke-dashoffset 2s ease, opacity 0.3s ease-in-out;
  }
  
  .list a.active .scribble{
    stroke-dashoffset: 0;
    opacity: 1;
  }
  
  @media screen and (max-width: 1000px) {
    .list {
      position: fixed;
      top: 0;
      background-color: rgba(0,0,0, 0.7);
  
      /* background-color: transparent; */
  
      margin-top: 0;
  
      display: flex;


      right: -100%;

      transition: right 0.4s ease-in-out;
  
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
  
      width: 100vw;
      height: 100vh;
  
      transition: right 0.4s;
  
      flex-direction: column;
  
      align-items: center;
      justify-content: center;
    }

    .scribble{
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      opacity: 0;
      stroke: #fff; 
      stroke-width: 2;

      mix-blend-mode: difference;
    }
  
    .list a:hover .scribble {
      stroke-dashoffset: unset;
      opacity: 0;
    }
    
    .list a.active .scribble{
      stroke-dashoffset: 0;
      opacity: 1;
    }
    
    .logo{
      padding-left: 1rem;
    }
  
    .list a {
      text-decoration: none;
      color: #fff;
    
      font-size: 48px;
      font-weight: 800;
    
      margin: 0 10px;
      padding: 0px 10px;
    }
  
    .nav__toggle{
      font-size: 3rem;
      color: #000;
      cursor: pointer;
  
      display: flex;
      padding-right: 1rem;
  
    }
  
    .nav__close{
      font-size: 3rem;
      color: #fff;
      cursor: pointer;
      position: absolute;
  
      top:20px;
      right: 20px;
  
      transition: .2s linear;
    }
  
    .nav__close:hover{
      color: #fff;
    }
  
    .showMenu{
      right: 0;
    }
  
    .header{
      display: flex;
  
      flex-direction: row;
      
      justify-content: space-between;
  
      align-items: center;

      mix-blend-mode: unset;
  
      padding: 1rem 0;
    }

    .mixed{
      mix-blend-mode: difference;
      filter: invert(1);
    }
  
    .list a.active {
      font-weight: 900;
      text-decoration:none;
  
      color: #bebebe;
    }

    .languageToggle {
      height: 3rem;
      padding: 0.5rem 0.25rem 0.5rem 0.75rem;
      border: 0px solid black;
      border-radius: 1rem;
    }
    .langImg {
      width: 25px;
    }
    select {
      font-size: 1.5rem;
      font-weight: 800;
    }
  }