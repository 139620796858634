.about-container{
    width: 100%;

    padding: 100px 0px;

    min-height: 100vh;

    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Vertical alignment */
    align-items: center; /* Center align items horizontally */

    position: relative;
    overflow: hidden; /* Hide any overflow to maintain effect */
}

.why-us{
    font-family: "Bodoni Moda SC", serif;
    /* font-family: "Raleway", sans-serif; */
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;

    font-size: 28px;

    margin: 0;

    text-align: center; /* Center align text */
}

.why{
    margin: 0;

    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;

    font-size: 36px;

    color: #fff;

    mix-blend-mode: difference;
    
    max-width: 600px;
}

.why-1{
    text-align: left;

    align-self: flex-start;
}

.why-2{

    text-align: right;

    align-self: flex-end;
}

.why-3{

    text-align: center;

    align-self: center;
}

.why-4{
    align-self: flex-start;

    text-align: left;
}

.img{
    max-width: 250px;
    height: auto;

    position: absolute;
}

.img-1{

    left: 10%;
    top: 30%;
}

.img-2{

    top: 10%;
}

.img-3{

    top: 45%;
    right: 10%;
}

@media screen and (max-width: 1650px) {
    .about-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Vertical alignment */
    }
    .why{
        font-size: 42px;
        max-width: 600px;
    }
  }

  @media screen and (max-width: 1400px) {
    .why{
        font-size: 36px;
        max-width: 500px;
    }
    .img{
        max-width: 200px;
    }
  }

  @media screen and (max-width: 1000px) {
    .about-container{
        justify-content: space-evenly; /* Vertical alignment */
    }
    .why{
        font-size: 28px;
        max-width: 500px;
    }
    .img{
        max-width: 150px;
        filter: brightness(0.3);
    }

    .img-1{

        left: 10%;
        top: 60%;
    }
    
    .img-2{
    
        top: 35%;
    }
    
    .img-3{
    
        top: 30%;
        right: 10%;
    }

    .about-container{
        padding-top: 50px;
    }
  }

  @media screen and (max-width: 650px) {
    .about-container{
        justify-content: space-evenly; /* Vertical alignment */
        
    }

    .why-1, .why-2, .why-3, .why-4{
        text-align: center;
    
        align-self: center;
    }

    .img-1{

        left: 10%;
        top: 50%;
    }
    
    .img-2{
    
        top: 25%;
    }
    
    .img-3{
    
        top: 70%;
        right: 10%;
    }
  }

  @media screen and (max-width: 500px) {
    .why{
        font-size: 24px;
        max-width: 500px;
    }
  }