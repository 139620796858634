.landingContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;

    display: flex;
    flex-direction: row;
    padding: calc(var(--nav-bar-height)) 0 calc(175px + 50px) 0;

    z-index: -1;
    background-color: #000;

    align-items: center;
    justify-content: space-between;

    pointer-events: none;

    transition: 0.3s ease-in-out;

    z-index: 10;
}

.aztechMxHeader {
    /* font-family: "Caesar Dressing", system-ui;
    font-weight: 400;
    font-style: normal; */
    width: 100%;

    font-family: "Raleway", sans-serif;

    font-size: clamp(60px, 12vw, 175px);
    line-height: 1;
    font-weight: bold;
    text-align: center;
    color: #fff;

    font-weight: 100;

    z-index: 95;
    white-space: nowrap;
}

.aztechMxContainer {
    width: 100%;
    /* height: var(--nav-bar-height); */
    /* top: var(--nav-bar-height); */
    bottom: var(--nav-bar-height);
    display: flex;
    position: absolute;
    bottom: 0;
    align-items: center;
}

.landingElement{
    width: calc(50% - 275px);
    min-height: 100vh;

    cursor: pointer;
    color: #000;

    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: center;

    pointer-events: auto;
}

.landingElement:nth-child(1) {
    text-align: right;

    align-items: flex-end;
}

.landingElement:nth-child(2) {
    text-align: left;

    align-items: flex-start;
    /* font-size: 300px; */
}

.textContainer{
    transition: 0.6s ease-in-out;

    mix-blend-mode: difference;
    filter: invert(1);
}

.landingContainer:hover{
    background-color: #fff;
}

.landingElement:hover .textContainer{
    transform: scale(1.2);
    z-index: 99;
}

.textContainer h1{
    margin: 0;
    font-size: 110px;
    font-weight: 100;
}

.textContainer p{
    margin: 0;
    font-size: 18px;
}

.landingElement:nth-child(3) .textContainer h1 {
    font-size: 90px;
}

@media screen and (max-width: 1500px) {
    .landingElement{
        width: calc(50% - 225px);
    }

    .landingElement:nth-child(3) .textContainer h1 {
        font-size: 70px;
    }

    .textContainer h1{
        font-size: 82px;
    }
    
    .textContainer p{
        font-size: 18px;
    }
    
}

@media screen and (max-width: 1300px) {
    .landingElement{
        width: calc(50% - 250px);
    }

    .landingElement:nth-child(3) .textContainer h1 {
        font-size: 56px;
    }

    .textContainer h1{
        font-size: 64px;
    }
    
    .textContainer p{
        font-size: 16px;
    }
    
}

@media screen and (max-width: 1200px) {
    .textContainer h1{
        font-size: 48px;
    }
    
    .textContainer p{
        font-size: 14px;
    }
    
}

@media screen and (max-width: 1000px) {
    .textContainer h1 {
        font-size: 48px;
    }
    
    .textContainer p {
        font-size: 14px;
    }
    
    .landingContainer {
        flex-direction: column;

        z-index: 10;
    }
    
    .landingElement {
        width: 90%;
        min-height: calc(50% - 500px);
    }
}

@media screen and (max-width: 600px) {
    .aztechMxContainer {
        height: var(--nav-bar-height);
        position: fixed;
        top: 0;
    }
    .aztechMxHeader {
        font-size: 24px;
    }
    .landingContainer {
        padding: var(--nav-bar-height) 0;
        overflow: hidden;
    }

    .textContainer h1 {
        font-size: 56px;
    }
    
    .textContainer p{
        font-size: 14px;
    }
    
    .landingContainer{
        flex-direction: column;

        z-index: 10;
    }
    
    .landingElement{
        width: fit-content;
        min-height: calc(50% - 500px);
    
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 375px) {
    .aztechMxHeader {
        font-size: 18px;
    }
}

@media screen and (max-height: 900px) and (max-width: 600px) {
    .landingContainer{
        padding: calc(var(--nav-bar-height) + 3rem) 0;
    }
}

@media screen and (max-height: 800px) and (max-width: 600px) {
    .landingContainer{
        padding: calc(var(--nav-bar-height) + 2.25rem) 0;
    }
}

@media screen and (max-height: 750px) and (max-width: 600px) {
    .landingContainer{
        padding: calc(var(--nav-bar-height) + 1.25rem) 0;
    }
}

@media screen and (max-height: 700px) and (max-width: 600px) {
    .landingContainer{
        padding: calc(var(--nav-bar-height) + 1rem) 0;
    }
}

@media screen and (max-height: 650px) {
    .landingContainer{
        padding: calc(var(--nav-bar-height) + 0.75rem) 0;
    }
}

@media screen and (max-height: 600px) {
    .landingContainer{
        padding: var(--nav-bar-height) 0;
    }
}

