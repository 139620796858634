.sliderSection {
    font-family: Raleway, sans-serif;
    margin: 0;
    text-align: center;
}

.sliderSection h1 {
    font-weight: bold;
    font-size: 2rem;
    display: block;
    margin: 0;
}

.ourProjectsContainer {
    margin: var(--section-margin);
}

.ourProjects {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    /* opacity: 0; */
    border-radius: var(--global-card-corner-radius);
    background-color: var(--bento-bkg);
}

.bentoSlider {
    display: flex;
    position: relative;

    margin: 1rem auto;
    border-radius: var(--global-card-corner-radius);
}

.project {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.projectText {
    position: fixed;
    font-weight: 300;
    font-size: 2rem;
    display: block;
    margin: 0;
    bottom: 3rem;
    background-color: var(--bento-bkg);
}

.projectTextInverted {
    position: fixed;
    font-weight: 300;
    font-size: 2rem;
    display: block;
    margin: 0;
    bottom: 3rem;
    background-color: #000;
    color: var(--bento-bkg);
}

.linkStyle {
    cursor: inherit;
}

@media(max-width: 1366px) {
    
}
@media(max-width: 1024px) {
    .sliderSection h1 {
        font-size: 1.75rem;
    }
    .projectText, .projectTextInverted {
        font-size: 1.75rem;
    }
}
@media(max-width: 768px) {
    .sliderSection h1 {
        font-size: 1.5rem;
    }
    .projectText, .projectTextInverted {
        font-size: 1.5rem;
    }
}
@media(max-width: 600px) {
    .projectText, .projectTextInverted {
        font-size: 1.25rem;
        bottom: 2.5rem;
    }
}

@media(max-width: 425px) {
    .projectText, .projectTextInverted {
        font-size: 1rem;
        bottom: 2rem;
    }
}
