.container{
    width: 100vw;
    min-height: 100vh;

}

.shaderContainer{
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
}

.headerContainer{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    mix-blend-mode: difference;
    
}

.titleContainer{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;
}

.titleContainer h1{
    margin: 0;

    font-size: 120px;
}

.title1{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;

    letter-spacing: 50px;

    margin-right:-50px !important;
}

.title2{

  font-family: "Bodoni Moda SC", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;


  letter-spacing: 80px;   
  margin-right:-80px !important;
}

.scrollContainer{
    position: absolute;

    bottom: 20px;
    right: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    writing-mode: vertical-rl; 
    color: #fff;
}

.scrollContainer svg{
    max-width: 50px;
    max-height: 50px;
    
    transform: rotate(270deg) scaleX(-1);
}

.scrollContainer p{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;

    font-size: 18px;
    margin: 0;
}

.headerAuxiliarContainer{
    background-color: transparent;
    z-index: 1;

    position: relative;

    min-width: 100vw;
    min-height: 100vh;
}

.contentContainer{
    background-color: #fff;
    position: relative;

    min-width: 100vw;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuredWork{
    padding-top: 100px;
    width: 95vw;
}

@media screen and (max-width: 1000px) {
    .titleContainer h1{
        font-size: 90px;
    }
    
    .title1{
        letter-spacing: 50px;
        margin-right:-50px !important;
    }
}

@media screen and (max-width: 750px) {
    .titleContainer h1{
        font-size: 64px;
    }
    
    .title1{
        letter-spacing: 30px;
        margin-right:-30px !important;
    }

    .title2{
        letter-spacing: 60px;
        margin-right:-60px !important;
    }

    .scrollContainer svg{
        max-width: 40px;
        max-height: 40px;
    }
    
    .scrollContainer p{
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .titleContainer h1{
        font-size: 48px;
    }
    
    .title1{
        letter-spacing: 30px;
        margin-right:-30px !important;
    }

    .title2{
        letter-spacing: 50px;
        margin-right:-50px !important;
    }
}

@media screen and (max-width: 450px) {
    .titleContainer h1{
        font-size: 36px;
    }
}