.sceneContainer{
    min-width: 100vw;
    min-height: 100vh;

    position: fixed !important;
    top: 0;
    left: 0;

    z-index: 2;
    pointer-events: none;
}

.sceneContainer canvas{
    width: 100vw;
    height: 100vh;

    position: inherit;
}

.videoContainer img{
    width: 1024px;
    height: 670px;

    border: none;
    border-radius: 20px;
}

.videoContainer iframe{
    width: 1024px;
    height: 670px;

    border: none;
    border-radius: 20px;
}

.videoContainer video{
    width: 1024px;
    height: 670px;

    border: none;
    border-radius: 20px;


    position: inherit;
}