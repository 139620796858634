.servicesSection {
    font-family: Raleway, sans-serif;
    margin: 0;
    text-align: center;
}

.serviceName {
    opacity: 0;
    transform: translateY(30px); /* Initial position below the original spot */
}

.servicesBodyText {
    font-weight: 300;
    font-size: 1.5rem;
    display: block;
    padding: 0 !important;
    margin: 0;
}

.hrStyle {
    margin: 1.25rem;
    width: 100%;
}

.bentoGrid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* grid-template-rows: repeat(auto-fill, minmax(auto, 200px)); */

    grid-gap: 1rem;
    /* max-width: 1300px; */
    margin: 0 2%;
    padding-top: 5rem;
    padding-bottom: 1rem;
}
.bentoGrid div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /* max-height: 200px; */

    padding: 1.5rem;
    opacity: 0;
    border-radius: var(--global-card-corner-radius);
    background-color: var(--bento-bkg);
    /* transition: 1s; */
}
.bentoGrid img {
    width: 70%;
    margin: auto;
    display: block;
}
.bentoGrid h1 {
    font-weight: bold;
    font-size: 2rem;
    display: block;
    margin: 0;
}
.bentoGrid h2 {
    font-weight: 500;
    font-size: 2rem;
    display: block;
    margin: 0;
}
.bentoGrid h3 {
    /* color: var(--bento-bkg); */
    /* font-family: Lexend Deca; */
    font-weight: 500;
    font-size: 1.25rem;
    display: block;
    margin: 0;
}
.bentoGrid h4 {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
    display: block;
    margin: 0;
}

.ourServicesGrid {
    grid-column: 1/13;
    grid-row: 1/2;
    margin: auto;
    width: 35%;
}
.firstServiceGrid {
    grid-column: 1/5;
    grid-row: 2/3;
}
.secondServiceGrid {
    grid-column: 5/9;
    grid-row: 2/3;
}
.thirdServiceGrid {
    grid-column: 9/13;
    grid-row: 2/3;
}
.fourthServiceGrid {
    grid-column: 1/5;
    grid-row: 3/4;
}
.fifthServiceGrid {
    grid-column: 5/9;
    grid-row: 3/6;
    padding: 0 !important;
}
.fifthServiceGrid img {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--global-card-corner-radius);
}
.sixthServiceGrid {
    grid-column: 9/13;
    grid-row: 3/4;
}
.seventhServiceGrid {
    grid-column: 1/5;
    grid-row: 4/6;
    padding: 0 !important;
}
.seventhServiceGrid img {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--global-card-corner-radius);
}

.eighthServiceGrid {
    grid-column: 9/13;
    grid-row: 4/5;
    padding: 0 !important;
}
.eighthServiceGrid img {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--global-card-corner-radius);
}

.ninthServiceGrid {
    grid-column: 9/13;
    grid-row: 5/6;
}
.tenthServiceGrid {
    grid-column: 1/5;
    grid-row: 6/7;
}
.eleventhServiceGrid {
    grid-column: 5/13;
    grid-row: 6/7;
    padding: 0 !important;
}
.eleventhServiceGrid img {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 4/1;
    border-radius: var(--global-card-corner-radius);
}
.whyUsGrid {
    grid-column: 1/9;
    grid-row: 7/8;
}
.whyUsFirst {
    grid-column: 1/5;
    grid-row: 8/9;
    flex-direction: column;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.whyUsSecond {
    grid-column: 5/9;
    grid-row: 8/9;
    flex-direction: column;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.whyUsThird {
    grid-column: 1/5;
    grid-row: 9/10;
    flex-direction: column;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.whyUsFourth {
    grid-column: 5/9;
    grid-row: 9/10;
    flex-direction: column;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.twelfthServiceGrid {
    grid-column: 9/13;
    grid-row: 7/9;
    padding: 0 !important;
}
.twelfthServiceGrid img {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 3/2;
    border-radius: var(--global-card-corner-radius);
}

.thirteenthServiceGrid {
    grid-column: 9/13;
    grid-row: 9/10;
    padding: 0 !important;
}
.thirteenthServiceGrid img {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 3/2;
    border-radius: var(--global-card-corner-radius);
}

@media(max-width: 1366px) {
    
}
@media(max-width: 1024px) {
    .bentoGrid h1 {
        font-size: 1.75rem;
    }
    .bentoGrid h2 {
        font-size: 1.75rem;
    }
    .bentoGrid h3 {
        font-size: 1.25rem;
    }
    .ourServicesGrid {
        width: 40%;
    }
    .servicesBodyText {
        font-size: 1.25rem;
    }

}
@media(max-width: 768px) {
    .bentoGrid {
        margin: 0 3%;
    }
    .bentoGrid h1 {
        font-size: 1.5rem;
    }
    .bentoGrid h2 {
        font-size: 1.5rem;
    }
    .bentoGrid h3 {
        font-size: 1rem;
    }
    .ourServicesGrid {
        width: 50%;
    }
    .servicesBodyText {
        font-size: 1rem;
    }
}
@media(max-width: 600px) {
    .bentoGrid {
        margin: 0 4%;
        /* grid-template-rows: repeat(auto-fill, minmax(100px, 300px)); */
    }
    .bentoGrid div {
        padding: 1rem;
    }
    .ourServicesGrid {
        width: 65%;
    }
    .firstServiceGrid {
        grid-column: 1/13;
        grid-row: 2/3;
    }
    .secondServiceGrid {
        grid-column: 1/13;
        grid-row: 5/6;
    }
    .thirdServiceGrid {
        grid-column: 1/13;
        grid-row: 7/8;
    }
    .fourthServiceGrid {
        grid-column: 1/13;
        grid-row: 3/4;
    }
    .fifthServiceGrid {
        grid-column: 1/13;
        grid-row: 6/7;
    }
    .fifthServiceGrid img {
        aspect-ratio: 1/1;
    }
    .sixthServiceGrid {
        grid-column: 1/13;
        grid-row: 8/9;
    }
    .seventhServiceGrid {
        grid-column: 1/13;
        grid-row: 4/5;
    }

    .eighthServiceGrid {
        grid-column: 1/13;
        grid-row: 9/10;
    }
    .eighthServiceGrid img {
        aspect-ratio: 2/1;
    }

    .ninthServiceGrid {
        grid-column: 1/13;
        grid-row: 10/11;
    }
    .tenthServiceGrid {
        grid-column: 1/13;
        grid-row: 11/12;
    }
    .eleventhServiceGrid {
        display: none !important;
    }
    .whyUsGrid {
        grid-column: 1/13;
        grid-row: 12/13;
        flex-direction: column;
        padding: 1rem !important;
    }
    .whyUsFirst {
        grid-column: 1/13;
        grid-row: 13/14;
        padding: 1rem !important;
    }
    .whyUsSecond {
        grid-column: 1/13;
        grid-row: 14/15;
        padding: 1rem !important;
    }
    .whyUsThird {
        grid-column: 1/13;
        grid-row: 15/16;
        padding: 1rem !important;
    }
    .whyUsFourth {
        grid-column: 1/13;
        grid-row: 16/17;
        padding: 1rem !important;
    }
    .twelfthServiceGrid {
        display: none !important;
    }
    
    .thirteenthServiceGrid {
        grid-column: 1/13;
        grid-row: 17/18;
    }
    .thirteenthServiceGrid img {
        aspect-ratio: 3/2;
    }
}

@media(max-width: 425px) {
    .bentoGrid {
        margin: 0 5%;
    }
    .ourServicesGrid {
        width: 80%;
    }
}
