.followerCircle {
    position: fixed;
    width: 2rem;
    height: 2rem;
    background-color: white;
    /* background-color: rgba(0, 0, 0, 0.5); */
    mix-blend-mode: difference;
    border-radius: 50%;
    opacity: 0;
    scale: 0;
    /* border: solid;
    border-color: #000;
    border-width: 2px; */
    pointer-events: none; /* Ensure the circle doesn't block interactions */
    transform: translate(-50%, -50%); 
    z-index: 1000;
}

.followerText {
    position: absolute;
    transform: translate(-50%, -50%); 
}

.linkToSvg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 20px;
    height: 20px;
    pointer-events: none;
}
