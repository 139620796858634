.container{
    background-color: #fff;
    min-width: 100vw;
    min-height: 100vh;

    overflow: hidden;
}

.headerContainer{
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    padding-top: 150px;
}

.buttonContainer{
    min-width: 50%;
    position: relative;
}

.buttonContainer button{
    margin-left: 50px;

    border: none;
    background-color: #fff;

    position: relative;

    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 24px;

    cursor: pointer;
}

.buttonContainer button:hover{
    text-decoration: underline;
}

.buttonContainer button span{
    position: absolute;

    font-size: 12px;
    width: fit-content;
}

.buttonContainer button svg{
    transition: 0.2s linear;
}

.buttonContainer button:hover .open{
    transform: rotate(90deg);
}

.buttonContainer button:hover .close{
    transform: rotate(180deg);
}

.aboutContainer{
    min-width: 50%;
}

.aboutContainer p{
    margin: 0;
    max-width: 400px;

    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;

    font-size: 24px;
}

.projectsContainer{
    padding: 150px 50px 0px 50px;
}

.projectElement{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 10px 0px;
    
    cursor: pointer !important;

    text-decoration: none;
    color: #000;

}

.projectElement p{
    margin: 0;

    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;

    font-size: 24px;
    text-wrap: nowrap;

    width: 200px;
}

.projectElement:hover{
    border-bottom: 1px solid #000;
}

.projectElement:hover .imgContainer img{
    opacity: 1;
}

.imgTextContainer{
    display: flex;
    flex-direction: row;
    gap: 30px;

}

.imgContainer{
    position: relative;
    width: 300px;
}

.imgContainer img{
    opacity: 0;
    width: 300px;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contactContainer{
    min-width: 100vw;
    min-height: 100vh;

    padding: 175px 0px;
    position: relative;
}

.filterContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 25px;
}

@media screen and (max-width: 1000px) {
    .projectElement{
        flex-wrap: wrap;
        padding: 20px 0px;
    }
}

@media screen and (max-width: 850px) {
    .projectElement{
        padding: 30px 0px;
    }

    .projectElement p{
        font-size: 22px;
    }

    .title{
        text-align: left;
    }

    .description{
        text-align: center;
    }

    .date{
        text-align: right;
    }

    .imgContainer{
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .projectElement{
        padding: 30px 0px;

    }

    .title{
        text-align: left;
    }

    .description{
        text-align: right;
    }

    .date{
        text-align: left;
    }

    .headerContainer{
        flex-wrap: wrap;
        flex-direction: column-reverse;

        padding: 0px 30px;
        padding-top: 150px;

        gap: 50px;
    }

    .buttonContainer button{
        margin: 0;
        font-size: 32px;
    }

}

@media screen and (max-width: 500px) {
    .imgContainer{
        display: none;
    }

    .title{
        text-align: left;
    }

    .description{
        text-align: left;
    }

    .date{
        text-align: right;
        min-width: 100%;
    }

}