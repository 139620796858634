.textContainer h1{
    font-size: 42px !important;
    font-family: "Raleway", sans-serif !important;

    font-weight: 300 !important;
    margin: 0 !important;

    letter-spacing: 20px;

    margin-right:-20px !important;

    color: #fff !important;
}

.textContainer span {
    color: inherit; /* Ensures the span inherits the color of its parent */
    display: inline-block; /* Necessary for animations like transform to work properly */

    width: 400px;
    text-align: left;
}


@media screen and (max-width: 1000px) {
    .textContainer h1{
        font-size: 32px !important;
        letter-spacing: 20px;
        margin-right:-20px !important;
    }
    
    .textContainer span {
        width: 375px;
        text-align: left;
    }
    
}

@media screen and (max-width: 750px) {
    .textContainer h1{
        font-size: 18px !important;
        letter-spacing: 10px;
        margin-right:-10px !important;
    }
    
    .textContainer span {
        width: 250px;
        text-align: left;
    }
    
}

@media screen and (max-width: 450px) {
    .textContainer h1{
        font-size: 14px !important;
        letter-spacing: 10px;
        margin-right:-10px !important;
    }
    
    .textContainer span {
        width: 200px;
        text-align: left;
    }
    
}
