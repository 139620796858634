.animationContainer {
    top: var(--nav-bar-height) !important;
    height: calc(100vh - 2* var(--nav-bar-height) - 150px) !important;
    width: 100vw !important;
    position: fixed !important;
    left: 0;

    background-color: transparent;

    pointer-events: none !important;

    z-index: 1;
}

.sceneContainer{
    position: relative;
    width: 100%;
    height: 100%;
}

.sceneTitle{
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    z-index: 99;

    color: #fff;
    mix-blend-mode: difference;

    width: 250px;
}

.sceneTitle h1{
    font-size: 18px;
    text-align: center;

    font-weight: 100;

    /* font-family: "Bodoni Moda SC", serif; */
    font-family: "Raleway", sans-serif;
}

@media screen and (max-width: 600px) {
    .animationContainer{
        top: 0 !important;
        height: inherit !important;
    }
}
