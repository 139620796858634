:root {
    --swiper-theme-color: var(--bento-bkg);
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--global-card-corner-radius);
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--global-card-corner-radius);
}

.swiper-pagination-bullet {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    color: #fff;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
    color: #000;
    /* background: #007aff; */
    background: var(--bento-bkg);
}

@media(max-width: 600px) {
    .swiper-pagination-bullet {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 18px;
    }
}

@media(max-width: 425px) {
    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
    }
}
