.contactContainer {
    width: 100vw;
    height: 100vh;

    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.figuresContainer {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
    pointer-events: none;
}

.infoContainer {
    z-index: 2;
    mix-blend-mode: difference;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.infoContainer h1 {
    font-family: "Bodoni Moda SC", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    /* Use clamp for responsive font sizing */
    /* The one below works fine from big screens to 768px, after that it breaks in the solutions page because of the change in section margins */
    /* font-size: clamp(30px, 13vw, 220px); */
    font-size: clamp(1.875rem, -0.5rem + 11.875vw, 13.75rem);
    letter-spacing: clamp(10px, 3vw, 50px);
    margin-right: clamp(-50px, -3vw, -10px) !important;

    text-align: center;

    margin: 0;
}

.infoContainer h2 {
    font-family: "Raleway", sans-serif;

    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;

    color: #fff;

    font-size: clamp(0.875rem, -0.1rem + 2.6vw, 2.5rem);
    letter-spacing: clamp(0.625rem, -0.875rem + 4vw, 3.125rem);
    margin-right: clamp(-50px, -3vw, -10px) !important;

    text-align: center;

    margin: 0;
}

.infoContainer p {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #fff;
    font-size: clamp(0.75rem, 0.675rem + 0.375vw, 1.125rem);
    text-align: center;
    margin: 0;
}

.mail {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #fff;
    display: block;
    font-size: clamp(1.125rem, 0.85rem + 1.375vw, 2.5rem);
    text-align: center;
    color: #fff;
    text-decoration: none;
}

.people {
    display: flex;
    justify-content: space-around;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.people a {
    display: block;
    font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
    color: #fff;
    text-decoration: none;
}

.infoContainer a:hover {
    text-decoration: line-through;
}

.footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 425px) {
    .people {
        flex-direction: column;
        align-items: center;
    }
}
